import React, { useState, useEffect, Fragment } from "react";
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, DocumentDuplicateIcon, CreditCardIcon } from '@heroicons/react/20/solid'

import { useUserState } from "contexts/user";

const solutions = [
  {
    name: 'Проверка от учител',
    description: 'Наш учител ще разгледа подробно всички отговори на теста',
    icon: IconOne,
  },
  {
    name: 'Писмена рецензия',
    description: 'Ще получите рецензията в писмен вид по имейл',
    icon: IconTwo,
  },
  {
    name: 'Допълнителна помощ',
    description: 'Рецензията включва персонален акцент над пропуските в теста',
    icon: IconThree,
  },
  {
    name: 'Оценка по критерии',
    description: 'Ще получите оценка по официалните критерии за оценяване',
    icon: IconFour,
  }
]

export default function RecensionButton({
  test
}) {
  const [isPulsing, setIsPulsing] = useState(true);
  const { user } = useUserState();

  useEffect(() => {
    setTimeout(() => {
      setIsPulsing(false);
    }, 6000);
  })

  return (
    <div className="top-16 w-full max-w-sm px-4">
      <Popover className="sm:relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'text-white' : 'text-white/90'}
                group inline-flex items-center rounded-md bg-sky-600 px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 shadow-lg ${isPulsing ? 'animate-pulse' : 'hover:bg-sky-500 transition-colors ease-in-out duration-300'}`}
            >
              <DocumentDuplicateIcon className="h-5 w-5 text-white/90 mx-1" aria-hidden="true" />
              <span>Рецензия</span>
              <ChevronDownIcon
                className={`${open ? 'text-sky-300' : 'text-sky-300/70'}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-sky-300/80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute w-[92%] left-[4%] mt-3 sm:w-screen max-w-md sm:left-1/2 z-20 sm:-translate-x-1/2 transform sm:px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-2xl">
                  <div className="relative grid gap-8 bg-white py-6 px-5 sm:p-7 lg:grid-cols-2">
                    {solutions.map((item) => (
                      <div
                        key={item.name}
                        href={item.href}
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/50"
                      >
                        <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                          <item.icon aria-hidden="true" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-neutral-900">
                            {item.name}
                          </p>
                          <p className="text-sm text-neutral-500">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bg-neutral-50 p-4">
                    <div
                      className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-neutral-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/50"
                    >
                      <span className="flex items-center">
                        <span className="text-sm font-medium text-neutral-900">
                          Директна обратна връзка
                        </span>
                      </span>
                      <p className="block text-sm text-neutral-500 mt-3">
                        Рецензията се изготвя от наш учител и изпраща на имейла на вашия акаунт. Освен точна оценка, ще получите обратна връзка и допълнителни обяснения върху пропуските ви, както и препоръка за следващи стъпки.
                      </p>
                      <p className="block text-sm text-neutral-500 mt-3">
                        При въпроси, потърсете ни на <i><u>info@elenor.bg</u></i>.
                      </p>
                    </div>
                    <form action="/stripe/create-checkout-session" method="POST">
                      {/* hidden field with the lookup_key of the Price */}
                      <input type="hidden" name="lookup_key" value={'RECENSION'} />
                      <input type="hidden" name="user_id" value={user.id} />
                      <input type="hidden" name="test_id" value={test.id} />

                      <button id="checkout-and-portal-button" type="submit"
                        className="bg-sky-600 text-white shadow-sm hover:bg-sky-500 transition-colors ease-in-out duration-300 w-full mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                      >

                        <CreditCardIcon className="h-5 w-5 inline mx-2 -mt-0.5" aria-hidden="true" />
                        Заяви рецензия
                      </button>
                    </form>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

function IconOne() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#f0f9ff" />
      <path
        d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
        stroke="#7dd3fc"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
        stroke="#bae6fd"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
        stroke="#bae6fd"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconTwo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#f0f9ff" />
      <path
        d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
        stroke="#7dd3fc"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
        stroke="#bae6fd"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#f0f9ff" />
      <rect x="13" y="32" width="2" height="4" fill="#bae6fd" />
      <rect x="17" y="28" width="2" height="8" fill="#bae6fd" />
      <rect x="21" y="24" width="2" height="12" fill="#bae6fd" />
      <rect x="25" y="20" width="2" height="16" fill="#bae6fd" />
      <rect x="29" y="16" width="2" height="20" fill="#7dd3fc" />
      <rect x="33" y="12" width="2" height="24" fill="#7dd3fc" />
    </svg>
  )
}

function IconFour() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#7dd3fc" style={{background: '#f0f9ff', padding: '6px', 'borderRadius': '7px'}}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
    </svg>
  )
}
