import React, { useState, useEffect } from "react";

import Breadcrumbs from "./Breadcrumbs";
import Question from "./Question";
import SelfAssessmentWizard from "./SelfAssessmentWizard";
import ChooseMode from "./ChooseMode";
import RecensionButton from "./RecensionButton";
import SlideOver from "components/commons/SlideOver";
import GoPremium from "components/commons/GoPremium";
import ResultPercentage from "components/commons/ResultPercentage";
import DialogModal from "components/commons/DialogModal";
import MyMath from "components/commons/MyMath";
import Mathable from "components/commons/Mathable";
import ReactHtmlParser from 'react-html-parser';
import ReactCardFlip from 'react-card-flip';

import testsApi from "apis/tests";
import { paginateCrumbs } from './breadcrumbPaginator'
import { subscribe, publish } from "common/events";
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon, DocumentDuplicateIcon } from '@heroicons/react/20/solid'

const Classic = ({
  test,
  renderingFromAdminPreview = false
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [displayedQuestions, setDisplayedQuestions] = useState(test.questions);
  const [paginatedQuestions, setPaginatedQuestions] = useState(paginateCrumbs(test.questions));
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [lastTestTaking, setLastTestTaking] = useState(test.last_test_taking);

  // results, correctResults, guessedQuestions can be removed as variables and read from lastTestTaking
  const [results, setResults] = useState(test.last_test_taking?.results || {})
  const [correctResults, setCorrectResults] = useState(test.last_test_taking?.correct_results || {})
  const [guessedQuestions, setGuessedQuestions] = useState(test.last_test_taking?.guessed_questions || [])
  const [slideOverOpen, setSlideOverOpen] = useState(false)
  const [hintedQuestion, setHintedQuestion] = useState(null)
  const [questionsPendingAssessment, setQuestionsPendingAssessment] = useState(findPendingAssessmentQuestions())
  const [showSelfAssessment, setShowSelfAssessment] = useState(false)
  const [flippedQuestions, setFlippedQuestions] = useState([])
  const [mode, setMode] = useState('learn')
  const [examModeRunning, setExamModeRunning] = useState(false)

  function findPendingAssessmentQuestions(lastTaking) {
    const taking = lastTaking || lastTestTaking;
    if (taking?.results == null) {
      return [];
    }

    return test.questions.filter(q => taking.pending_self_assessment_questions.includes(q.id));
  }

  const recordAnswer = async answer => {
    // without this, clicking a question with Math in it marks it as clicked with a delay.
    await new Promise(r => setTimeout(r, 1));
    setResults({ ...results, ...answer });
  }

  const unrecordAnswer = async forQuestionId => {
    const {[forQuestionId]: _, ...rest} = results;
    setResults(rest)
  }

  const scrollToTop = () => {
    setTimeout(() => { // without timeout it doesn't work on Firefox
      window.scroll({top: 0, behavior: 'smooth'})
    }, '10');
  }

  const submitAnswers = async () => {
    try {
      testsApi.submitAnswers(test.id, {results: results}).then((response) => {
        setCorrectResults(response.data.test_taking.correct_results);
        setGuessedQuestions(response.data.test_taking.guessed_questions);
        setLastTestTaking(response.data.test_taking);
        setResults(response.data.test_taking.results);
        setQuestionsPendingAssessment(findPendingAssessmentQuestions(response.data.test_taking));
      });
    } catch (error) {
      logger.error(error);
    } finally {
      setExamModeRunning(false);
      scrollToTop();
    }
  };

  const retake = () => {
    scrollToTop()
    setResults({})
    setCorrectResults({})
    setGuessedQuestions([])
    setLastTestTaking(null)
    setShowSelfAssessment(false);
    publish('retake-test-clicked');
  }

  const isSubmitVisible = () => {
    if (renderingFromAdminPreview) {
      return false;
    }
    return test.visible && Object.keys(correctResults).length === 0;
  }

  const isRetakeVisible = () => {
    if (renderingFromAdminPreview) {
      return false;
    }
    return test.visible && !isSubmitVisible();
  }

  const isSubmitEnabled = () => {
    // return test.questions.length === Object.keys(results).length
    return Object.keys(results).length > 0 // has given at least one answer
  }

  const onQuestionClick = async question => {
    setSelectedQuestion(question);
    setDisplayedQuestions([question]);
  }

  const onHomeClick = async => {
    setDisplayedQuestions(test.questions)
    setSelectedQuestion(null)
  }

  const onFlagClick = async => {
    setDisplayedQuestions(test.questions.filter((question) => !Object.keys(results).includes(question.id)))
    setSelectedQuestion(null)
  }

  const openHint = (question) => {
    setSlideOverOpen(true)
    setHintedQuestion(question)
  }

  function onProceedSelfAssessment(updatedTestTaking = null) {
    if (updatedTestTaking) {
      setCorrectResults(updatedTestTaking.correct_results);
      setGuessedQuestions(updatedTestTaking.guessed_questions);
      setResults(updatedTestTaking.results);
      setQuestionsPendingAssessment(findPendingAssessmentQuestions(updatedTestTaking));
      setLastTestTaking(updatedTestTaking);
    }

    setShowSelfAssessment(true);
  }

  function renderSelfAssessment() {
    const isOne = questionsPendingAssessment.length === 1
    const title = isOne ? `Оценете въпрос №${questionsPendingAssessment[0].index}` : 'Оценете следните въпроси'
    const subtitle = isOne ? 'Един от въпросите в теста има сложен отговор, който не можем да оценим автоматично. Сравнете вашия отговор с примерен верен отговор и оценете дали вашият е правилен или грешен.' : 'Някои от въпросите в теста имат сложен отговор, който не можем да оценим автоматично. Сравнете вашите отговори с примерни верни отговори и оценете дали вашият е правилен или грешен.'
    const proceedLabel = isOne ? 'Оцени въпросa сега' : 'Оцени въпросите сега'

    return (
      <div>
        <DialogModal
          title={title}
          subtitle={subtitle}
          cancelLabel={'Ще оценя по-късно'}
          proceedLabel={proceedLabel}
          onProceed={onProceedSelfAssessment}
          iconOnTop={
            <>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <PencilSquareIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
            </>
          }
        />
        {showSelfAssessment && (
          <div className="my-10">
            <SelfAssessmentWizard testTaking={lastTestTaking} questions={questionsPendingAssessment} onProceed={onProceedSelfAssessment}/>
          </div>
        )}
      </div>
    )
  }

  function selectedAnswerOf(question) {
    if (results[question.id]?.hasOwnProperty('answer')) {
      return results[question.id].answer
    }

    return results[question.id] || ''
  }

  function status(question) {
    if (question.doubles_question_id) {
      return null;
    }
    if (question.kind === 'open_with_long_answer_subquestions') {
      return lastTestTaking?.results[question.id];
    }
    if (guessedQuestions.includes(question.id)) {
      return 'guessed';
    }
    if (lastTestTaking?.pending_self_assessment_questions?.includes(question.id)) {
      return 'pending';
    }
    if (lastTestTaking?.results) {
      return 'wrong';
    }
    return 'unanswered';
  }

  function selfAssessClicked() {
    setShowSelfAssessment(false);
    setTimeout(() => {
      setShowSelfAssessment(true);
    }, '1');
  }

  function flipQuestion(question) {
    if (flippedQuestions.includes(question.doubles_question_id)) {
      const id = question.doubles_question_id;
      const height = document.getElementById(`${id}-back`).clientHeight;
      document.getElementById(`${id}-front`).setAttribute('style', `min-height:${height}px`);

      setFlippedQuestions(flippedQuestions.filter(q => q !== id));

      setTimeout(() => {
        document.getElementById(`${id}-front`).style.removeProperty('min-height');
      }, '1000'); // this delay should be the same as flipSpeedBackToFront
    } else {
      const id = question.id;
      const height = document.getElementById(`${id}-front`).clientHeight
      document.getElementById(`${id}-back`).setAttribute('style', `min-height:${height}px`);

      setFlippedQuestions([...flippedQuestions, id]);
    }
  }

  useEffect(() => {
    function handleResizeWindow() {
      setWidth(window.innerWidth);
      setPaginatedQuestions(paginateCrumbs(test.questions))
    }
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  function shouldDisplayQuestion(question) {
    return displayedQuestions.includes(question) ||
      (displayedQuestions.map((q) => q.id).includes(question.doubles_question_id) && Object.keys(correctResults).length > 0)
  }

  function renderQuestion(question) {
    return (
      <Question
        question={question}
        selectedAnswer={selectedAnswerOf(question)}
        results={results[question.id]}
        resultCorrectAnswer={correctResults[question.id]}
        status={status(question)}
        recordAnswer={recordAnswer.bind(this)}
        unrecordAnswer={unrecordAnswer.bind(this)}
        openHint={openHint.bind(this)}
        flipQuestion={flipQuestion}
        examModeRunning={examModeRunning}
        pointsMap={lastTestTaking?.points_map}
        key={question.id}
      />
    )
  }

  function renderQuestionWithDouble(question) {
    return (
      <ReactCardFlip
        isFlipped={flippedQuestions.includes(question.id)}
        flipDirection="horizontal"
        flipSpeedBackToFront="1"
        flipSpeedFrontToBack="1.3"
        cardStyles={{
          front: { zIndex: 'unset', transformStyle: 'initial' },
          back: { zIndex: 'unset', transformStyle: 'initial' }
        }}
      >
        <div className="w-full" id={`${question.id}-front`}>
          {renderQuestion(question)}
        </div>

        <div className="w-full bg-white" id={`${question.id}-back`}>
          {renderQuestion(question.double)}
        </div>
      </ReactCardFlip>
    )
  }

  function hideQuestions() {
    return lastTestTaking == null && mode === 'exam' && !examModeRunning;
  }

  function timeForTaking() {
    return test.time_for_taking_in_seconds || test.questions.length * 3 * 60 // 3 minutes per question
  }

  useEffect(() => {
    subscribe('start-self-assessment-clicked', selfAssessClicked);
  });

  return (
    <>
      {!test.visible && (
        <div className="flex items-center justify-center">
          <GoPremium title="Нужен е абонамент за да достъпите теста" subtitle="За да направите този тест, необходимо е да се запишете за един от нашите абонаменти. По-долу може да видите всички въпроси от теста без техните отговори."/>
        </div>
      )}

      {!isRetakeVisible() && !renderingFromAdminPreview && (
        <ChooseMode
          setMode={setMode}
          setExamModeRunning={setExamModeRunning}
          timeForTakingInSeconds={timeForTaking()}
          onExamTimeOver={submitAnswers}
        />
      )}

      <div className="grid justify-center">
        {isRetakeVisible() && (
          <>
            {questionsPendingAssessment.length > 0 && (
              <>
                {renderSelfAssessment()}
                <div className="mt-1 mb-4 rounded-md bg-cyan-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-cyan-500" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-cyan-700">Тестът съдържа въпроси за самооценка.</p>
                      <p className="mt-3 text-sm md:ml-6 md:mt-0 cursor-pointer">
                        <span onClick={selfAssessClicked} className="flex whitespace-nowrap font-medium text-cyan-700 hover:text-cyan-600">
                          Оцени
                          <span className="my-auto pl-0.5" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                              <path d="M13.488 2.513a1.75 1.75 0 0 0-2.475 0L6.75 6.774a2.75 2.75 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.75 2.75 0 0 0 .892-.596l4.261-4.262a1.75 1.75 0 0 0 0-2.474Z" />
                              <path d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9A.75.75 0 0 1 14 9v2.25A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2H7a.75.75 0 0 1 0 1.5H4.75Z" />
                            </svg>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!(questionsPendingAssessment.length > 0) && (
              <>
                {test.recension != null && test.recension.status === 'paid' && (
                  <div className="bg-sky-100 shadow sm:rounded-lg mb-7">
                    <div className="px-4 py-5 sm:p-6">
                      <div className="flex gap-1.5 items-center justify-center">
                        <DocumentDuplicateIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                        <h3 className="text-base font-semibold leading-6 text-neutral-900">Успешно заплатена рецензия</h3>
                      </div>
                      <div className="mt-2 max-w-xl text-sm text-neutral-500">
                        <p>
                          Рецензията се изготвя от наш учител и изпраща на имейла на вашия акаунт. При въпроси, потърсете ни на <i>info@elenor.bg</i>.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex m-auto">
                  <div>
                    <ResultPercentage showGrade={true} correct={lastTestTaking?.points} total={test.points} grade={lastTestTaking?.grade} />
                  </div>
                  {test.recension == null && (
                    <RecensionButton test={test} />
                  )}
                </div>
              </>
            )}
          </>
        )}

        <div className="m-auto">
          {paginatedQuestions.map((questions, index) => (
            <Breadcrumbs
              questions={questions}
              results={results}
              testTaking={lastTestTaking}
              selectedQuestion={selectedQuestion}
              onQuestionClick={onQuestionClick.bind(this)}
              onHomeClick={onHomeClick.bind(this)}
              onFlagClick={onFlagClick.bind(this)}
              key={index}
            />
          ))}
        </div>
      </div>

      <h2 className="text-center pt-16 pb-5">
        {test.title}
      </h2>

      {test.intro && (
        <h4 className="whitespace-pre-line md:max-w-3xl m-auto pt-4 pb-14 text-l font-normal text-neutral-600 leading-6 text-center">
          {ReactHtmlParser(test.intro)}
        </h4>
      )}
      {test.image_url && (
        <img src={test.image_url} className="m-auto p-6"/>
      )}

      <SlideOver
        open={slideOverOpen}
        setOpen={setSlideOverOpen}
        title={`Жокер за въпрос №${hintedQuestion?.index}`}
        content={
          <>
            <div className="leading-5 whitespace-pre-line text-neutral-900"> <Mathable content={<MyMath text={hintedQuestion?.hint?.text}/>}/> </div>
            {hintedQuestion?.hint?.image_url && (
              <img src={hintedQuestion?.hint?.image_url} className="m-auto p-5" />
            )}
          </>
        }
      />

      <div className={`${hideQuestions() ? 'hidden' : ''} questions-table-height w-full flex flex-col items-center justify-center overflow-x-auto`}>
        {test.questions.map((question) =>
          <div className={`${shouldDisplayQuestion(question) ? 'w-full' : 'hidden'}`} key={question.id}>
            {!question.double && renderQuestion(question)}
            {question.double && renderQuestionWithDouble(question)}
          </div>
        )}

        {isSubmitVisible() && (
          isSubmitEnabled() ? (
            <button onClick={submitAnswers} type="button" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 m-5 dark:bg-primary-600 dark:hover:bg-primary-600 focus:outline-none dark:focus:ring-primary-700">Завърши теста</button>
          ) : (
            <button type="button" className="text-white bg-neutral-400 dark:bg-primary-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 m-5 text-center" disabled>Завърши теста</button>
          )
        )}

        {isRetakeVisible() && (
          <button onClick={retake} type="button" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 m-5 dark:bg-primary-600 dark:hover:bg-primary-600 focus:outline-none dark:focus:ring-primary-700">Повтори теста отново</button>
        )}
      </div>
    </>
  );
};

export default Classic;
